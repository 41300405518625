<script setup>
import { computed, defineEmits, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import { setErrors, validate } from "@/user-account/utils/input-rules/validation";
import PasswordResetForm from "@/user-account/components/auth/PasswordResetForm.vue";
import CodeVerificationForm from "@/user-account/components/auth/CodeVerificationForm.vue";
import { api } from "@/services";
import { useAuth } from "@/user-account/composables/useAuth";
import { useUserService } from "@/user-account/composables/useUserService";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);
const { t } = useI18n();
const requestId = ref();

const mode = computed(() => (requestId.value ? "verification" : "registration"));

const { setToken } = useAuth();
const { setUser } = useUserService();

async function reset(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const result = await api.auth.resetPassword({
      country: form[0].value,
      phone: form[1].value,
      password: form[2].value,
    });

    if (result.requestId) {
      requestId.value = result.requestId;
    }
  } catch (error) {
    setErrors(form, error.errors);
  }
}

async function verify(form) {
  console.log("verify form", form);
  if (!validate(form)) {
    return;
  }

  try {
    const { user, token } = await api.auth.verifyPasswordReset({
      code: form[0].value,
      requestId: requestId.value,
    });

    setToken(token);
    setUser(user);

    emit("close", false);

    router.push({ path: "/" });

    toast.info("Your password was updated!");

    requestId.value = null;
  } catch (error) {
    setErrors(form, error.errors);
  }
}

async function resend() {
  const result = await api.auth.resendPasswordResetCode({ requestId: requestId.value });

  if (result?.requestId) {
    requestId.value = result.requestId;
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="" />
          </div>

          <div class="modal-body">
            <PasswordResetForm v-if="mode === 'registration'" @submit="reset" />

            <CodeVerificationForm
              v-if="mode === 'verification'"
              @submit="verify"
              @resend="resend"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}

h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: auto;
  position: relative;
  width: 100%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;

  .login-view {
    .forgot-password {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      justify-content: end;
      cursor: pointer;
      color: #3886ec;
      font-family: "Poppins";

      // transition: 0.4s all ease-in;
      // opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .login-button {
      margin-top: 16px;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }

    .login-now {
      text-align: center;
      color: var(--be-primary-btn);
      cursor: pointer;
    }

    .register-button {
      margin: 1em 0 3em;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 0.9em;
      margin-bottom: 10px;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
