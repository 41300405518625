import { onUnmounted, ref } from "vue";
import { useBetslip } from "@/sportsbook/composables/useBetslip";

const data = ref(null);
const error = ref(null);

const { selections, multipleStake, save } = useBetslip();
export function useEventSource(url) {
  let worker;

  const initializeWorker = () => {
    // Create a new Worker instance pointing to the worker script in the public directory
    worker = new Worker("/worker.js");

    // Send the EventSource URL to the worker
    worker.postMessage({ url: import.meta.env.VITE_APP_SSE_URL });

    // Listen for messages from the worker
    worker.onmessage = (event) => {
      const { type, data: eventData, error: eventError } = event.data;
      if (type === "data") {
        data.value = eventData;
        const newUpdatedData = eventData.map((item) => {
          const updatedSelections = selections.value.map((item1) => {
            if (item1.id == item.id) { return { ...item1, isActive: item.is_active, odds: item.price }; } else {
              return { ...item1 };
            }
          });

          selections.value = updatedSelections;

          return item;
        });
      } else if (type === "error") {
        error.value = eventError;
        console.error("EventSource failed:", eventError);
      }
    };
  };

  //   onMounted(() => {
  //     initializeWorker();
  //   });

  onUnmounted(() => {
    // Terminate the worker when the component is destroyed
    if (worker) {
      worker.terminate();
    }
  });

  return { data, error, initializeWorker };
}
