import {
  type RouteRecordRaw,
  type Router,
  createRouter,
  createWebHashHistory,
  useRoute,
} from "vue-router";
import { utilityRoutes } from "./routes";
import { registerRouterGuards } from "./router-guards";
import OneComponentLayout from "@/app/layouts/OneComponentLayout.vue";
import NotFoundPage from "@/app/views/NotFoundPage.vue";

import { casinoRoutes } from "@/casino/routes/casino";
import { virtualGamesRoutes } from "@/virtual-games/routes/virtual-games";
import { sportsbookRoutes } from "@/sportsbook/routes/sportsbook";
import { accountRoutes } from "@/user-account/routes/account";
import { liveBettingRoutes } from "@/livebetting/routes/livebetting";
import { eventsRoutes } from "@/events/routes/events";

const routes: RouteRecordRaw[] = [
  utilityRoutes,
  casinoRoutes,
  virtualGamesRoutes,
  sportsbookRoutes,
  accountRoutes,
  liveBettingRoutes,
  eventsRoutes,
  {
    path: "/:pathMatch(.*)*",
    component: OneComponentLayout,
    children: [
      {
        path: "",
        name: "not-found",
        component: NotFoundPage,
      },
    ],
  },
];

declare module "vue-router" {
  interface RouteMeta {
    requiresAuth?: boolean;
    excludesAuth?: boolean;
    backRoute?: string;
  }
}

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Router Guards
registerRouterGuards(router);

export { router, useRoute };
