import { type RouteRecordRaw } from "vue-router";
import CasinoLayout from "@/casino/layouts/CasinoLayout.vue";
import CasinoView from "@/casino/views/CasinoView.vue";
import GameView from "@/casino/views/GameView.vue";

export const casinoRoutes: RouteRecordRaw = {
  path: "/casino",
  component: CasinoLayout,
  children: [
    {
      path: "",
      name: "casino",
      redirect: "/casino/lobby",
    },
    {
      path: ":category",
      name: "casino.category",
      component: CasinoView,
    },
    {
      path: "providers/:providerId",
      name: "casino.provider",
      component: CasinoView,
    },
    {
      path: "game/:id",
      name: "casino.game",
      component: GameView,
      props: true,
      // meta: { requiresAuth: true },
    },
  ],
};
