export function capitalizeHyphenatedString(str) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function capitalizeString(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function spaceStringToHyphenatedString(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join("-");
}

export function convertToTimeFormat(time) {
  const [min, sec] = time.split(":").map((num) => parseInt(num));
  return `${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
}

export const countryMapping = {
  "Non-Geographic": "",
  World: "wd",
  Africa: "",
  Americas: "",
  Asia: "",
  Europe: "",
  Oceania: "",
  Burundi: "bi",
  Comoros: "km",
  Djibouti: "dj",
  Eritrea: "er",
  Ethiopia: "et",
  Kenya: "ke",
  Madagascar: "mg",
  Malawi: "mw",
  Mauritius: "mu",
  Mayotte: "yt",
  Mozambique: "mz",
  Rwanda: "rw",
  Seychelles: "sc",
  Somalia: "so",
  Uganda: "ug",
  Tanzania: "tz",
  Zambia: "zm",
  Zimbabwe: "zw",
  Angola: "ao",
  Cameroon: "cm",
  Chad: "td",
  "Congo Rep.": "cg",
  "Congo DR": "cd",
  "Equatorial Guinea": "gq",
  Gabon: "ga",
  "Sao Tome and Principe": "st",
  Algeria: "dz",
  Egypt: "eg",
  Libya: "ly",
  Morocco: "ma",
  Sudan: "sd",
  Tunisia: "tn",
  Botswana: "bw",
  Lesotho: "ls",
  Namibia: "na",
  "South Africa": "za",
  Swaziland: "sz",
  Benin: "bj",
  "Burkina Faso": "bf",
  "Cape Verde": "cv",
  "Cote d'Ivoire": "ci",
  Gambia: "gm",
  Ghana: "gh",
  Guinea: "gn",
  "Guinea-Bissau": "gw",
  Liberia: "lr",
  Mali: "ml",
  Mauritania: "mr",
  Niger: "ne",
  Nigeria: "ng",
  Senegal: "sn",
  "Sierra Leone": "sl",
  Togo: "tg",
  Anguilla: "ai",
  "Antigua and Barbuda": "ag",
  Aruba: "aw",
  Bahamas: "bs",
  Barbados: "bb",
  "Cayman Islands": "ky",
  Cuba: "cu",
  Dominica: "dm",
  "Dominican Republic": "do",
  Grenada: "gd",
  Guadeloupe: "gp",
  Haiti: "ht",
  Jamaica: "jm",
  Martinique: "mq",
  Montserrat: "ms",
  "Netherlands Antilles": "an",
  "Puerto Rico": "pr",
  "Saint Kitts and Nevis": "kn",
  "Saint Lucia": "lc",
  "Saint Vincent and the Grenadines": "vc",
  "Trinidad and Tobago": "tt",
  "Turks and Caicos Islands": "tc",
  Belize: "bz",
  "Costa Rica": "cr",
  "El Salvador": "sv",
  Guatemala: "gt",
  Honduras: "hn",
  Mexico: "mx",
  Nicaragua: "ni",
  Panama: "pa",
  Argentina: "ar",
  Bolivia: "bo",
  Brazil: "br",
  Chile: "cl",
  Colombia: "co",
  Ecuador: "ec",
  Guyana: "gy",
  Paraguay: "py",
  Peru: "pe",
  Suriname: "sr",
  Uruguay: "uy",
  Venezuela: "ve",
  Bermuda: "bm",
  Canada: "ca",
  Greenland: "gl",
  "United States of America": "us",
  Kazakhstan: "kz",
  Kyrgyzstan: "kg",
  Tajikistan: "tj",
  Turkmenistan: "tm",
  Uzbekistan: "uz",
  China: "cn",
  "Hong Kong": "hk",
  Macao: "mo",
  "Korea DPR": "kp",
  Japan: "jp",
  Mongolia: "mn",
  "Korea Rep": "kr",
  Afghanistan: "af",
  Bangladesh: "bd",
  Bhutan: "bt",
  India: "in",
  Iran: "ir",
  Maldives: "mv",
  Nepal: "np",
  Pakistan: "pk",
  "Sri Lanka": "lk",
  "Brunei Darussalam": "bn",
  Cambodia: "kh",
  Indonesia: "id",
  Laos: "la",
  Malaysia: "my",
  Myanmar: "mm",
  Philippines: "ph",
  Singapore: "sg",
  Thailand: "th",
  "Timor-Leste": "tl",
  Vietnam: "vn",
  Armenia: "am",
  Azerbaijan: "az",
  Bahrain: "bh",
  Cyprus: "cy",
  Georgia: "ge",
  Iraq: "iq",
  Israel: "il",
  Jordan: "jo",
  Kuwait: "kw",
  Lebanon: "lb",
  "Occupied Palestinian Territory": "ps",
  Oman: "om",
  Qatar: "qa",
  "Saudi Arabia": "sa",
  Syria: "sy",
  Turkey: "tr",
  "U.A.E.": "ae",
  Yemen: "ye",
  Belarus: "by",
  Bulgaria: "bg",
  "Czech Republic": "cz",
  Hungary: "hu",
  Poland: "pl",
  Moldova: "md",
  Romania: "ro",
  Russia: "ru",
  Slovakia: "sk",
  Ukraine: "ua",
  Denmark: "dk",
  Estonia: "ee",
  "Faeroe Islands": "fo",
  Finland: "fi",
  Guernsey: "gg",
  Iceland: "is",
  Ireland: "ie",
  "Isle of Man": "im",
  Jersey: "je",
  Latvia: "lv",
  Lithuania: "lt",
  Norway: "no",
  Sweden: "se",
  "Great Britain and Northern Ireland": "gb",
  Albania: "al",
  Andorra: "ad",
  "Bosnia and Herzegovina": "ba",
  Croatia: "hr",
  Gibraltar: "gi",
  Greece: "gr",
  Italy: "it",
  Malta: "mt",
  Montenegro: "me",
  Portugal: "pt",
  "San Marino": "sm",
  Serbia: "rs",
  Slovenia: "si",
  Spain: "es",
  "North Macedonia": "mk",
  Austria: "at",
  Belgium: "be",
  France: "fr",
  Germany: "de",
  Liechtenstein: "li",
  Luxembourg: "lu",
  Monaco: "mc",
  Netherlands: "nl",
  Switzerland: "ch",
  Australia: "au",
  "New Zealand": "nz",
  "Norfolk Island": "nf",
  Fiji: "fj",
  "New Caledonia": "nc",
  "Papua New Guinea": "pg",
  "Solomon Islands": "sb",
  Vanuatu: "vu",
  Guam: "gu",
  Kiribati: "ki",
  "Northern Mariana Islands": "mp",
  Palau: "pw",
  "American Samoa": "as",
  "Cook Islands": "ck",
  "French Polynesia": "pf",
  Niue: "nu",
  Samoa: "ws",
  Tonga: "to",
  Taiwan: "tw",
  "United Arab Emirates": "ae",
  "Brazil (general)": "br",
  "Rio Grande do Norte": "br",
  "Canada (general)": "ca",
  Andalusia: "es",
  "Canary Islands": "es",
  Catalonia: "es-ca",
  "Basque Country": "es",
  "Do not use": "",
  "Faroe Islands": "fo",
  "The Scottish Borders": "gb-sct",
  "South Korea (general)": "kr",
  "Macedonia, The Former Yugoslav Republic of (general)": "mk",
  Macau: "mo",
  "Palestine (general)": "ps",
  "Gaza Strip": "ps",
  "West Bank": "ps",
  Kosovo: "xk",
  "Sweden (general)": "se",
  "Tristan da Cunha": "sh",
  Svalbard: "sj",
  Texas: "us",
  "Zambia (general)": "zm",
  England: "gb-eng",
  Wales: "gb-wls",
  "Northern Ireland": "gb-nir",
  Scotland: "gb-sct",
  "South Sudan": "ss",
  "Test Region": "",
  Burma: "mm",
  "Ivory Coast": "ci",
  Tahiti: "pf",
  "U.S. Virgin Islands": "vi",
  Micronesia: "fm",
  Nauru: "nr",
  "Central African Rep.": "cf",
  "French Guyana": "gf",
  "British Virgin Islands": "vg",
  Mauretania: "mr",
  Surinam: "sr",
  "Former East Timor": "tl",
  "GB/Ireland": "gb",
  "Marshall Islands": "mh",
  Tuvalu: "tv",
  "Christmas Island": "cx",
  Curacao: "cw",
  Zanzibar: "tz",
  Yugoslavia: "",
  "West Germany": "",
  "East Germany": "",
  "Soviet Union": "",
  Czechoslovakia: "",
  "Dutch East Indies": "",
  Zaire: "",
  Crimea: "",
  "French Guiana": "gf",
};

export function calculateTotalMatchLength(matchesArray) {
  if (!matchesArray) {
    return 0;
  }

  let totalLength = 0;

  // Iterate through each element in the array
  matchesArray.forEach((league) => {
    // Accessing the 'matches' array within each league object
    Object.values(league.matches).forEach((match) => {
      // Incrementing the total length by 1 for each match
      totalLength++;
    });
  });

  return totalLength;
}

export function sortingOfArrayofObject(arrayOfObjects) {
  return arrayOfObjects.sort((a, b) => {
    let nameA = a.name.toUpperCase();
    let nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}

export function inActiveOdds(data) {
  const inActiveOdds = Object.values(data)
    .flatMap((obj) => Object.values(obj.tournaments))
    .flatMap((tournament) => Object.values(tournament.matches))
    .flatMap((match) => Object.values(match.markets))
    .flatMap((market) => Object.values(market.odds))
    .filter((odd) => !odd.isActive)
    .map((odd) => odd.id);
  return inActiveOdds;
}

export const matchStatuses = {
  Football: {
    PreMatch: "Pre",
    FirstHalf: "1H",
    HalfTime: "HT",
    SecondHalf: "2H",
    FullTimeNormalTime: "To ET",
    ExtraTimeFirstHalf: "ET1H",
    ExtraTimeHalfTime: "ETHT",
    ExtraTimeSecondHalf: "ET2H",
    FullTimeExtraTime: "FT",
    Penalties: "Pens",
    PostMatch: "End",
    MatchAbandoned: "Abandoned",
  },
  Basketball: {
    PreGame: "Pre",
    FirstQuarter: "Q1",
    BeforeSecondQuarter: "Break",
    SecondQuarter: "Q2",
    FirstHalf: "1stH",
    HalfTime: "HT",
    ThirdQuarter: "Q3",
    BeforeFourthQuarter: "Break",
    FourthQuarter: "Q4",
    SecondHalf: "2H",
    BeforeOvertime: "TO OT",
    Overtime: "OT",
    PostGame: "End",
    GameAbandoned: "Abandoned",
  },
  Tennis: {
    "WalkoverCompetitorOne (CompetitorOne wins)": "1Walk",
    "WalkoverCompetitorTwo (CompetitorTwo wins)": "2Walk",
    OnCourt: "OnCourt",
    WarmingUp: "WarmUp",
    BetweenPoints: "BetweenPoints",
    PointInProgress: "PointInProgress",
    Challenge: "Challenge",
    PlaySuspended: "Delay",
    ShortDelay: "Delay",
    MedicalTimeoutCompetitorOne: "MedicalTimeoutP1",
    MedicalTimeoutCompetitorTwo: "MedicalTimeoutP2",
    WinnerCompetitorOneByRetirementOrDefault: "1Winner",
    WinnerCompetitorTwoByRetirementOrDefault: "2Winner",
    WinnerCompetitorOne: "1Winner",
    WinnerCompetitorTwo: "2Winner",
  },
  "Ice Hockey": {
    PreMatch: "PreMatch",
    FirstPeriod: "Period1",
    EndOfFirstPeriod: "EndPeriod1",
    SecondPeriod: "Period2",
    EndOfSecondPeriod: "EndPeriod2",
    ThirdPeriod: "Period3",
    EndOfThirdPeriod: "EndPeriod3",
    OvertimePeriod: "OT",
    EndOfOvertimePeriod: "EndofOT",
    PenaltyShootout: "Pens",
    Suspended: "Suspended",
    Abandoned: "Abandoned",
    PostMatch: "End",
  },
  "Table Tennis": {
    PreGame: "Pregame",
    InPlay: "InPlay",
    BreakInPlay: "InPlay",
    PostGame: "End",
    GameAbandoned: "Abandoned",
  },
};

export function inActiveOddsWithoutTournament(data) {
  const inActiveOdds = Object.values(data)
    .flatMap((tournament) => Object.values(tournament.matches))
    .flatMap((match) => Object.values(match.markets))
    .flatMap((market) => Object.values(market.odds))
    .filter((odd) => !odd.isActive)
    .map((odd) => odd.id);
  return inActiveOdds;
}

export function uniqueArray(array) {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}

export function findNumber(array, numberToFind) {
  return array.includes(numberToFind);
}

export function convertToLocalTime(dateString) {
  let dateObj;

  // Check if the date string contains a time zone indication
  if (dateString.includes("T") || dateString.includes("Z")) {
    // If it contains 'T' or 'Z', it might be already in ISO format or contain time zone
    dateObj = new Date(dateString);
  } else {
    // Otherwise, assume it's in 'YYYY-MM-DD HH:mm:ss' format
    dateObj = new Date(dateString.replace(" ", "T") + "Z");
  }

  // Convert to local time
  return dateObj.toLocaleString();
}
