<script setup>
import { computed, ref, watch } from "vue";
import ProviderSelector from "./ProviderSelector.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";

import { debounce } from "@/app/utils/debounce";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { useRoute } from "@/app/router";
import { useRouter } from "vue-router";
import { useI18n } from "@/app/composables/useI18n";
import { useBetStore } from "../../../store";

const { t } = useI18n();
const route = useRoute();
const store = useBetStore();
const { state, categoriesExtended, updateGames } = useCasinoService();
const searchText = ref("");

const router = useRouter();

const providerSelectorIsVisible = computed(
  () => state.category.id !== 0 || Object.keys(state.selectedProviders).length == 0
);

watch(
  searchText,
  debounce(() => {
    state.searchText = searchText;
    updateGames();
  }, 700)
);

const handleCategory = (category) => {
  router.push({ path: `/casino/${category}` });
  store.setEmptyProvider();
};
</script>

<template>
  <section class="panel-wrapper">
    <div class="categories">
      <BaseButton
        v-for="category in categoriesExtended"
        :key="category.id"
        class="category text"
        :class="route.fullPath.substring(8, 13) === category.slug.substring(0, 5) ? 'activing' : ''"
        @click="() => handleCategory(category.slug)"
      >
        <BaseIcon
          :name="category.slug"
          :filter="
            route.fullPath.substring(8, 13) === category.slug.substring(0, 5)
              ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))'
              : ''
          "
        />
        <span class="category-name">{{ t(`casino.${category.slug}`) }}</span>
      </BaseButton>
    </div>
    <div class="filters" :class="{ 'without-provider-selector': !providerSelectorIsVisible }">
      <div
        :class="`search ${route.path == '/casino/lobby' && 'search-full'}`"
        v-if="route.path != '/casino/providers'"
      >
        <div class="prepend-inner">
          <BaseIcon name="magnify" size="28" />
        </div>
        <input
          v-model="searchText"
          aria-autocomplete="none"
          :placeholder="t('casino.search')"
          type="text"
        />
      </div>
      <ProviderSelector v-if="route.path != '/casino/lobby'" class="provider-select" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/thin-scrollbar.scss";

.panel-wrapper {
  background-color: var(--be-big-card-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile &,
  .tablet & {
    display: block;
  }

  .categories {
    display: flex;
    padding: 8px 10px 8px;
    margin-right: 20px;
    overflow-y: auto;

    .category {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      // white-space: nowrap;
      width: 116px;
      // opacity: 0.5;
      color: var(--be-disable-state);
      // color: #ccc;
      margin: 0 8px;
      font-weight: 600;
      font-size: 11px;
      // text-transform: uppercase;
      letter-spacing: 1px;
      padding-bottom: 9px;
      font-family: Rubik;
      .mobile &,
      .tablet & {
        font-size: 10px;
        flex-direction: column-reverse;
      }

      .category-name {
        margin: 2px 0 0;
        text-transform: none;
        height: 2.5em;
        display: flex;
        width: 111px;
        justify-content: center;
        align-items: center;
        line-height: 1.2;
        font-size: 14px;
        font-family: Rubik;
        .mobile &,
        .tablet & {
          margin: 0 0 5px;
        }
      }

      img {
        max-width: 24px;
        height: 24px;
      }
    }

    @media screen and (max-width: 550px) {
      .category {
        min-width: 116px;
        padding-bottom: 12px;
        padding-top: 3px;
      }
    }

    .activing {
      opacity: 1;
      background: var(--be-small-card-bg);
      color: var(--be-primary-btn);
      border-radius: 6px;
    }

    // .router-link-exact-active .category,
    // .router-link-active .category {
    //   opacity: 1 !important;
    // }
  }

  @media screen and (max-width: 550px) {
    .categories {
      padding: 5px 8px 4px;
      margin-right: 0px;
    }
  }

  .filters {
    display: flex;
    margin: 7px;
    height: 45px;

    // div {
    //   width: 50%;
    // }
    &.without-provider-selector .search {
      flex-basis: 100%;
    }
    .search {
      border-radius: 8px;
      // width: 200px;
      flex-basis: 50%;
      display: flex;
      min-width: 250px;
      // background-color: rgb(48, 49, 79);
      background-color: var(--be-small-card-bg);

      height: 50px;

      .prepend-inner {
        background: var(--be-secondary-btn);
        display: flex;
        align-items: center;
        border-radius: 8px 0 0 8px;
        padding: 16px;
        margin-right: 15px;
      }

      input {
        display: flex;
        flex: 1 1 auto;
        position: relative;
        padding: 8px 0 8px;
        max-width: 100%;
        min-width: 0;
        width: 100%;
        padding-left: 7px;
        border-width: 1px;
        border-color: #616060ad;
        color: var(--be-primary-text-color);
        background-color: var(--be-small-card-bg);
        border-radius: 8px;

        &:focus-visible {
          outline: none;
        }
      }
      ::placeholder {
        color: rgba(255, 255, 255, 0.5);
        opacity: 1; /* Firefox */
      }
    }

    .search-full {
      flex-basis: 100%;
    }

    @media screen and (max-width: 500px) {
      .search {
        height: 36px;
        border-radius: 4px;
        min-width: 50%;
        .prepend-inner {
          background: transparent;
          display: flex;
          align-items: center;
          border-radius: 4px 0 0 4px;
          padding: 10px;
          margin-right: 0px;
        }
        input {
          padding-left: 0px;
          border-radius: 4px;
        }
      }
    }
    .provider-select {
      flex-basis: 50%;
    }
  }
}
</style>
