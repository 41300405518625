<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
});
</script>

<template>
  <div class="progress-bar">
    <div class="progress" :class="{ active: props.loading }" />
  </div>
</template>

<style lang="scss" scoped>
// .progress-loader {
//   min-height: 4px;
// }
.progress-bar {
  background-color: var(--be-background-color);
  height: 0.3rem;
  position: relative;
  overflow: hidden;
  .progress {
    // background-color: var(--be-small-card-bg);
    background-color: #4d76a9;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 0;
    &.active {
      /* Move the bar infinitely */
      width: 40%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: progress-bar;
    }
  }
}

@keyframes progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
</style>
