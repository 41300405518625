<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { computed } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useRoute } from "@/app/router";
import BaseLayout from "@/app/layouts/BaseLayout.vue";
import Banner from "@/app/components/Banner.vue";
import InformationPanel from "@/sportsbook/components/InformationPanel.vue";

const route = useRoute();

const isVisibleBanner = computed(() => {
  return !route.matched.some(record => record.name === "sport.event");
});

const screenWidth = ref(window.innerWidth);
function handleResize() {
  screenWidth.value = window.innerWidth;
}

const gtm = useGtm();

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check

  gtm.trackEvent({
    event: "homepage",
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <BaseLayout>
    <div class="w-full">
      <Banner v-if="isVisibleBanner" mblbtn-label="Join the Excitement!" />

      <div class="sport-container">
        <div class="sportsbook-view">
          <router-view />
        </div>
      </div>
    </div>
    <InformationPanel v-if="screenWidth >= 568" class="notebook-only" />
  </BaseLayout>
</template>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.sportsbook-view {
  x .mobile &,
  .tablet & {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 500px) {
  .sport-container {
    display: block;
  }

  .sportsbook-view {
    width: 100%;
  }
}
</style>
