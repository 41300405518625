import { api } from "@/services";
import { router } from "@/app/router";
import { pingServer } from "@/app/utils/network";
import { useUserService } from "@/user-account/composables/useUserService";

const { user, setUser, isAuthenticated } = useUserService();
let token: string;

const AUTH_KEY = "betevet_token";
const longSessions = true;

/**
 * A hook that returns functions to handle authentication.
 * The login function takes in a username and password, logs the user in, and
 * redirects them to the dashboard page. If longSessions is true, the token is
 * saved in local storage. The logout function logs the user out and redirects
 * them to the login page. The init function initializes the token and user
 * with the stored token from local storage. The getToken function returns the
 * token.
 */
export function useAuth() {
  /**
   * An asynchronous function that logs the user in with their username and password,
   * and sets the token and user value. If longSessions is true, the token is saved in
   * local storage. Upon successful login, the user is redirected to the dashboard page.
   */
  async function login(params, handleAfterLogin) {
    const { user: u, token: t } = await api.auth.login(params);

    if (t) {
      if (u && !u.data.auth.emailVerification.verified) {
        router.push({
          name: "confirm-email",
          params: {
            email: u.email,
            username: u.username,
            password: params.password,
          },
        });
        return;
      }

      token = t;
      setUser(u);
      await pingServer();
      if (handleAfterLogin) {
        handleAfterLogin();
      }
      router.push({ name: "/" });
      if (longSessions) {
        window.localStorage.setItem(AUTH_KEY, token);
      }
    }
  }

  function setToken(newToken) {
    token = newToken;
    window.localStorage.setItem(AUTH_KEY, token);
  }

  /**
   * Logs the user out of the system.
   */
  function logout() {
    setUser(null);
    token = null;
    window.localStorage.removeItem(AUTH_KEY);
    window.localStorage.removeItem("activeWallet");
    if (router.currentRoute.value.path !== "/") {
      router.push({ name: "/" });
    }
  }

  /**
   * Initializes the application by checking if there is a stored token. If there
   * is, it sets the token and fetches the user. Otherwise, it sets the token to
   * null and removes it from local storage.
   *
   * @return {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async function initAuth(): Promise<void> {
    const storedToken = window.localStorage.getItem(AUTH_KEY);

    if (storedToken) {
      token = storedToken;
      const user = await api.users.getCurrentUser();
      if (user) {
        setUser(user);
      } else {
        token = null;
        window.localStorage.removeItem(AUTH_KEY);
      }
    }
    return Promise.resolve();
  }

  /**
   * Returns the value of the token.
   *
   * @return {string} The token value.
   */
  function getToken(): string {
    return localStorage.getItem(AUTH_KEY);
  }

  return {
    initAuth,
    login,
    logout,
    getToken,
    isAuthenticated,
    setToken,
  };
}
