<script setup>
import { useRoute } from "vue-router";
import BetSlip from "../../app/components/BetSlip/BetSlip.vue";
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import { useSportsdata } from "../../sportsbook/composables/useSportsdata";
import { useBetStore } from "../../../store";
import { useMarketGroups } from "../../sportsbook/composables/useMarketGroups.js";
import Row from "./Row.vue";
import { updateNestedProperties } from "@/app/utils/events";
import { useEventSource } from "@/sportsbook/composables/useEventSource";
import { router } from "@/app/router";
import { spaceStringToHyphenatedString } from "@/sportsbook/utils/general";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

const { t } = useI18n();

const { getSportBySlug, fetchSportEvents, fetchSportsEvents } = useSportsdata();
const { data, error } = useEventSource();
const { marketGroups, setLayoutMode, setSport } = useMarketGroups();
const sport = ref();
const sports = ref([]);
const tournaments = ref([]);
const store = useBetStore();
// const filters = ref({});
const loading = ref(false);

// load sport list with events

function getObjectById(array, id) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id == id) {
      return array[i];
    }
  }
  // Return null if no object with the given ID is found
  return null;
}

async function loadSports() {
  loading.value = true;
  sports.value = await fetchSportsEvents({
    timeFrame: "today",
    type: "live",
  });

  sport.value = Object.values(sports.value)[0];
  tournaments.value = Object.values(sports.value)[0].tournaments;
  loading.value = false;
}

const route = useRoute();

function eventTime(time) {
  return new Date(time);
}

watch(data, (newValue) => {
  if (newValue) {
    const newUpdatedData = newValue.map((item) => {
      const keys = [
        item.tournamentId,
        "matches",
        item.competitionId,
        "markets",
        item.marketType,
        "odds",
        item.id,
      ];
      const updates = {
        odds: item.price,
        isActive: item.is_active,
      };

      updateNestedProperties(tournaments.value, keys, updates);

      return item;
    });
  }
});

function eventTimeDay(time) {
  const day = eventTime(time).getDate().toString().padStart(2, "0");
  const month = (eventTime(time).getMonth() + 1).toString().padStart(2, "0");
  return `${day}/${month}`;
}

function eventTimeTime(time) {
  const hours = eventTime(time).getHours().toString().padStart(2, "0");
  const minutes = eventTime(time).getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

onMounted(async () => {
  loadSports();
});

const handleClickSport = (item) => {
  sport.value = item;
  tournaments.value = item.tournaments;
};
</script>

<template>
  <div class="livebetting">
    <div class="left-sec">
      <div class="breadcrumb">
        <div
          class="cursor-pointer"
          @click="
            () =>
              router.push({
                name: 'sportsbook',
              })
          "
        >
          Home
        </div>
        <div>/</div>
        <div>{{ t("sportsbook.live") }}</div>
        <div>/</div>
        <div>{{ t(`sports.${spaceStringToHyphenatedString(sport?.name || "")}`) }}</div>
      </div>
      <div class="live">
        <img class="live-icon" src="/assets/images/livebetting/live.svg" alt="" />

        <div v-if="tournaments">
          {{ t("sportsbook.live") }} ({{
            Object.values(tournaments).flatMap((item) => Object.values(item.matches)).length
          }})
        </div>
      </div>

      <div class="game-bar">
        <Carousel
          :transition="500"
          :breakpoints="{
            // 700px and up
            600: {
              itemsToShow: 12,
            },
            // 1024 and up
            300: {
              itemsToShow: 3,
              snapAlign: 'start',
            },
          }"
        >
          <Slide v-for="item in sports" :key="item.id">
            <div
              :class="`game ${sport?.id == item.id ? 'active-game' : ''}`"
              @click="() => handleClickSport(item)"
            >
              <div>{{ t(`sports.${item.slug}`) }}</div>
              <div class="game-icon">
                <div class="game-index">
                  {{
                    getObjectById(sports, item.id)?.tournaments.flatMap((item) => item.matches)
                      .length
                  }}
                </div>
                <BaseIcon
                  :name="item.icon"
                  :filter="
                    sport?.id == item.id ? 'drop-shadow(0px 0px 8px rgba(72, 255, 145, 0.5))' : ''
                  "
                />
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div v-if="tournaments" v-for="(tournament, sectionIndex) in Object.values(tournaments)">
        <div class="heading">
          {{ tournament?.name }}
        </div>
        <div class="header-bar">
          <div class="time">Time</div>
          <div class="score">Score</div>
          <div class="match">Match</div>
          <div class="full-time">
            <div>
              {{ Object.values(Object.values(tournament?.matches)[0].markets)[0]?.market?.name }}
            </div>
            <img src="/assets/images/livebetting/down.svg" alt="" />
          </div>
        </div>
        <div class="rows">
          <Row
            v-for="(match, matchIndex) in Object.values(tournament.matches)"
            :match-index="matchIndex"
            :match="match"
            :slug="sport?.name"
            :tournament-id="tournament?.id"
            :country-id="tournament?.country?.id"
          />
        </div>
      </div>
    </div>

    <BetSlip />
  </div>
</template>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.lock {
  width: 30px;
}

.livebetting {
  padding: 30px;
  display: flex;
  gap: 0 30px;
  .left-sec {
    width: 73%;
  }
  .right-sec {
    width: 27%;
  }

  @media screen and (max-width: 500px) {
    .left-sec {
      width: 100%;
    }
    .right-sec {
      display: none;
    }
  }
}

.black-text {
  color: #17271a;
}

.live {
  border-radius: 4px 4px 0px 0px;
  background: #f00;
  color: white;
  display: flex;
  width: 89px;
  height: 39px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 0 4px;

  .live-icon {
    width: 14px;
    height: 14px;
  }
}

.breadcrumb {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  display: flex;
  gap: 0 8px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.game-bar {
  border-radius: 0px 8px 8px 8px;
  background: var(--be-big-card-bg);

  padding: 10px 10px;

  .game {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    padding: 10px 15px 12px 15px;
    gap: 8px 0px;
    cursor: pointer;
    text-transform: capitalize;
    color: var(--be-disable-state);
    .game-icon {
      position: relative;
      width: fit-content;
    }

    .game-index {
      border-radius: 10px;
      background: #f00;
      width: 10px;
      height: 10px;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -2px;
      right: -8px;
    }
  }

  .dim-game {
    opacity: 0.5;
  }

  .active-game {
    background: var(--be-small-card-bg);
    color: var(--be-primary-btn);
    border-radius: 8px;
  }
}

.heading {
  font-size: 14px;
  margin: 16px 0;
}

.header-bar {
  background: var(--be-big-card-bg);
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  padding: 8px 10px;
  text-transform: uppercase;
  border-radius: 8px 8px 0 0;
}

.time {
  width: 20%;
  text-align: center;
}

.score {
  width: 20%;
  text-align: center;
}
.match {
  width: 35%;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .match {
    width: 95%;
    text-transform: uppercase;

    .match-name {
      width: 8 0px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }

    .match-v {
      width: 20px;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}

.full-time {
  width: 25%;
  display: flex;
  gap: 0 3px;
}

.rows {
  border: 1px solid var(--be-big-card-bg);
  border-radius: 0 0 8px 8px;
  .row {
    display: flex;
    padding: 10px;
    align-items: center;
    .time {
      color: #ff453a;
      text-transform: uppercase;
      font-size: 12px;
    }

    .match {
      font-size: 14px;
    }

    .boxes {
      display: flex;
      gap: 0 10px;

      .box {
        border-radius: 5px;
        background: var(--be-small-card-bg);
        width: 122.5px;
        height: 59px;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
      }

      .sub-label {
        font-size: 13px;
        color: white;
        opacity: 0.5;
      }
      .yellow-box {
        border-radius: 5px;
        background: var(--be-primary-btn);
        color: var(--be-primary-text-color);
        width: 122.5px;
        height: 59px;
        padding: 8px;
        display: flex;
        opacity: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }
    }
  }

  .dark {
    background: var(--be-big-card-bg);
  }
}

.stake-header {
  display: flex;
  justify-content: space-between;

  .stake-title {
    display: flex;
    gap: 0 10px;
    font-size: 14px;
  }
}

.stake {
  border-radius: 4px;
  background: var(--be-small-card-bg);
  width: 150px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  padding-left: 8px;
  .stake-btc {
    background: var(--be-divider);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 0 4px 4px 0;
  }
}

.delete-icon {
  width: 16px;
  height: 16px;
}

.detail {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  gap: 0 4px;
}

.digit {
  color: #f09426;
}

.currency-digit {
  color: var(--be-primary-btn);
}

.win-detail {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  gap: 0 3px;
}

.multiple {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px 0 8px 0;
  .clear-slip {
    display: flex;
    gap: 0 4px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 12px 0px;
  cursor: pointer;
  font-size: 14px;
}

.betslip {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .betslip-label {
    display: flex;
    gap: 0 6px;
  }
}

.slip-card {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
}
.boxes {
  display: flex;
  justify-content: space-between;
  .odd-box {
    border-radius: 8px;
    background: var(--be-big-card-bg);
    padding: 6px 16px 8px 16px;
    display: flex;
    width: 131px;
    height: 52px;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;

    .odd-digit {
      display: flex;
      color: var(--be-primary-btn);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 0 4px;
    }
  }
  .stake-box {
    border-radius: 4px;
    background: var(--be-small-card-bg);
    width: 134px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    padding-left: 10px;

    .stake-box-btc {
      background: #333360;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 52px;
      border-radius: 0 4px 4px 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .livebetting {
    padding: 14px;
  }
  .header-bar {
    display: none;
  }
  .rows {
    // border: none;
    border-radius: 8px;
    .row {
      flex-direction: column;
      .time {
        display: flex;
        justify-content: center;
        gap: 0 3px;
        width: 100%;
        margin-bottom: 4px;
      }
    }
  }

  .boxes {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
