<script setup>
import { computed, ref, watch } from "vue";
import { useBetStore } from "../../../store";
import GameActions from "@/casino/components/GameActions.vue";
import ContentLoader from "@/app/components/ContentLoader.vue";
import { api } from "@/services";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import { useGameHelper } from "@/virtual-games/composables/useGameHelper.js";
import { useI18n } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
});
const store = useBetStore();

const { activeWallet } = useUserService();

const { t } = useI18n();
const { startGame, selectedCurrency, loading } = useGameHelper();
const exchangeRates = ref([]);

const isFullscreen = ref(false);
const game = ref();

const userBalance = computed(() => {
  const balance = activeWallet.value.balance;
  const balanceCurrency = activeWallet.value.currency.ticker;
  const exchangeRate = exchangeRates.value.find(
    (rate) => rate.from === balanceCurrency && rate.to === selectedCurrency.value
  );
  if (!exchangeRate) {
    return "N/A";
  }

  return (Math.round(exchangeRate.rate * balance * 100) / 100).toFixed(2);
});

const gameCurrencies = import.meta.env.VITE_APP_GAME_CURRENCIES?.split(",");

function exitGame() {
  game.value.redirectUrl = null;
}

function onPlay(isDemo) {
  startGame(game, isDemo, store.handleShowLogin);
}

async function fetchData() {
  game.value = await api.virtualGames.game({ code: props.code });
  exchangeRates.value = await api.utils.fetchExchangeRates();

  if (window.innerWidth < 500) {
    watch(
      () => game.value.redirectUrl,
      async (url) => {
        if (url) {
          window.location.href = url;
        }
      }
    );
  }
}

fetchData();
</script>

<template id="game">
  <ProgressLoader :loading="loading" />
  <article v-if="game?.description" class="game-lobby" :class="{ fullscreen: isFullscreen }">
    <div
      v-if="!game.redirectUrl"
      class="lobby-content"
      :style="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(/assets/images/games/${game.code}.jpg)`,
      }"
    >
      <h1 class="game-name">
        {{ game.description }}
      </h1>
      <h4 class="provider">DS Virtual games</h4>
      <div class="currency-switch">
        <div class="balance-header">
          <div>{{ `${t("casino.display_balance_in")} ${selectedCurrency}` }}</div>
          <div class="balance">
            {{ userBalance }}
          </div>
        </div>
        <div class="currency-buttons">
          <BaseButton
            v-for="(gameCurrency, i) in gameCurrencies"
            :key="i"
            class="currency-button"
            :class="{ selected: selectedCurrency === gameCurrency }"
            @click="selectedCurrency = gameCurrency"
          >
            <div class="button-content">
              <BaseIcon :name="gameCurrency.toLowerCase()" size="40" />
              <span>{{ gameCurrency }}</span>
            </div>
          </BaseButton>
        </div>
      </div>
      <div class="play-buttons">
        <BaseButton class="play-button alight" @click="onPlay(false)">
          {{ t("casino.play") }}
        </BaseButton>
        <BaseButton class="play-button" @click="onPlay(true)">
          {{ t("casino.play_demo") }}
        </BaseButton>
      </div>
    </div>
    <div v-else class="game-wrapper">
      <GameActions v-model:is-fullscreen="isFullscreen" :game="game" class="lobby-actions" />
      <iframe
        id="game-iframe"
        :src="game.redirectUrl"
        frameborder="0"
        allowfullscreen
        target="_parent"
        class="game-iframe"
        @exitGame="exitGame"
      />
    </div>
  </article>
  <ContentLoader v-else />
</template>

<style lang="scss" scoped>
.game-lobby {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 46px;

  .desktop & {
    width: 90%;
  }
  .lobby-content {
    height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    // align-content: center;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
    // height: 100%;
    width: 100% !important;
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14),
      0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;

    .game-name {
      font-family: var(--be-header-font);
      font-size: 2.3em;
    }
    .provider {
      margin: 0.2em 0 3em;
    }
    .currency-switch {
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      .balance-header {
        margin-bottom: 16px;
        .balance {
          opacity: 0.8;
          text-align: center;
        }
      }

      .currency-button {
        background: transparent;
        border: #ffffff63 1px solid;
        color: #ffffff63;
        padding-top: 5px !important;
        height: 95px;

        &:first-child {
          margin-right: 10px;
        }

        &.selected {
          background: #ffffff;
          border: #ffffff 1px solid;
          color: #000000;
        }

        .button-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .play-buttons {
      .play-button {
        width: 150px;
        height: 3em;
        margin: 3em 1em;
      }
    }
  }

  .game-wrapper {
    position: relative;
    width: 100%;
    .game-iframe {
      width: 100%;
      height: 80vh;
    }
  }

  &.fullscreen {
    .lobby-actions {
      z-index: 200;
      position: fixed;
      bottom: 0px;
    }
    .game-wrapper {
      width: 100vw;
    }
    .lobby-actions {
      width: 100vw;
    }

    .lobby-content,
    .game-iframe {
      position: fixed;
      inset: 0px;
      height: 100%;
      width: 100%;
      border: none;
      margin: 0px;
      padding: 0px;
      overflow: hidden;
      z-index: 100;
    }
  }
}
</style>
