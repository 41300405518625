// import { ref, computed, watch } from 'vue';
import { computed, reactive, ref } from "vue";
import { useSportsdata } from "./useSportsdata.js";

export function useMarketGroups() {
  const sportsdata = useSportsdata();
  const layoutMode = ref(1);

  // const featuredMarkets = computed(() => {
  //     return sportsdata.featuredMarkets(sport.id) ?? [];
  // });
  const featuredMarkets = ref();
  // const featuredMarkets = ref(sportsdata.featuredMarkets(sport.id));

  // const marketGroups = ref([]);

  const marketGroups = computed(() => {
    if (layoutMode.value === 1 || featuredMarkets.value.length === 1) {
      return ref([reactive({
        markets: featuredMarkets.value,
        activeIndex: 0,
        // class: ""
      })]);
      // return mg;
    } else if (featuredMarkets.value.length > 1) {
      return ref([
        reactive({
          markets: [featuredMarkets.value[0]],
          activeIndex: 0,
          // class: ""
        }),
        reactive({
          markets: featuredMarkets.value.slice(1),
          activeIndex: 0,
          // class: ""
        }),
      ]);
    }
    return [];
  });

  // watch(
  //     marketGroups,
  //     () => {
  //         marketGroups.value.forEach(mg => {
  //             mg.activeGroup = mg.markets[mg.index]                ;
  //         });
  // });

  function setLayoutMode(mode) {
    layoutMode.value = mode;
  }

  function setSport(sport) {
    featuredMarkets.value = sportsdata.featuredMarkets(sport.id) ?? [];
  }

  return {
    marketGroups,
    setLayoutMode,
    setSport,
  };
}
