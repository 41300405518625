<script>
import BaseLayout from "@/app/layouts/BaseLayout.vue";

export default {
  name: "CasinoLayout",
  components: {
    BaseLayout,
  },
};
</script>

<template>
  <BaseLayout class="casino">
    <!-- <KeepAlive> -->
    <router-view />
    <!-- </KeepAlive> -->
  </BaseLayout>
</template>

<style scoped></style>
