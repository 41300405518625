<script setup>
import { computed } from "vue";
import { t } from "@/app/composables/useI18n";

const props = defineProps({
  bonus: {},
});

const bonusRolloverProgress = computed(() => {
  return Math.round((props.bonus?.currentBonusRollover * 100) / props.bonus?.maxBonusRollover);
});

const realRolloverProgress = computed(() => {
  return Math.round(
    (props.bonus?.currentRealFundsRollover * 100) / props.bonus?.maxRealFundsRollover
  );
});
</script>

<template>
  <div class="status">
    <div class="status-label" v-if="bonus?.maxBonusRollover > 0">
      {{ t("bonus.bonus_rollover") }}
      {{ Math.round(bonus?.maxBonusRollover / bonus?.initialAmount) }}x ({{
        bonus?.maxBonusRollover
      }}
      {{ bonus?.currency?.ticker }})
    </div>
    <div v-if="bonus.maxBonusRollover > 0">
      <div id="app">
        <div class="shell">
          <div class="bar" :style="{ width: `${bonusRolloverProgress}%` }">
            <span>{{ bonusRolloverProgress }}%</span>
          </div>
        </div>
        <!-- <button @click="makeProgress">+</button> -->
      </div>
    </div>
    <div class="status-label" v-if="bonus.maxRealFundsRollover > 0">
      {{ t("bonus.deposit_rollover") }}
      {{ Math.round(bonus.maxRealFundsRollover / bonus.initialAmount) }}x ({{
        bonus.maxRealFundsRollover
      }}
      {{ bonus?.currency?.ticker }})
    </div>
    <div id="app" v-if="bonus.maxRealFundsRollover > 0">
      <div class="shell">
        <div class="bar" :style="{ width: `${realRolloverProgress}%` }">
          <span>{{ realRolloverProgress }}%</span>
        </div>
      </div>
      <!-- <button @click="makeProgress">+</button> -->
    </div>
    <div class="status-description">
      {{ t("bonus.short_rules_description") }}
    </div>
    <div class="rules">{{ t("bonus.rules_link_title") }}</div>
  </div>
</template>

<style lang="scss" scoped>
.status {
  margin-top: 16px;
  .status-label {
    font-family: Poppins-reg;
    font-size: 16px;
    font-style: normal;
    margin-bottom: 5px;
  }

  .status-description {
    font-family: Poppins-reg;
    font-size: 16px;
    font-style: normal;
    margin: 20px 0;
  }
}

#app {
  margin-bottom: 16px;
}

.shell {
  height: 34px;
  width: 100%;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px;
}

.bar {
  background: linear-gradient(90deg, #3886ec 0%, #544fe5 100%);

  height: 26px;
  width: 15px;
  border-radius: 100px;
  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em;
  }
}

.rules {
  color: #3886ec;
  background-clip: text;
  font-family: Poppins-reg;
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  line-height: normal;
  text-decoration-line: underline;
}
</style>
