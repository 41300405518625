<script setup>
import { brands } from "@/app/utils/brands";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";

const { getDefaultCurrency } = useCurrencies();
const defaultCurrency = getDefaultCurrency();

const { referAndEarn } = useReferAndEarn();

const { name } = brands[import.meta.env.VITE_APP_BRAND];

const code = "YOUR_CODE";

const termsUrl = `/docs/${name}/t&c.docx`;
</script>

<template>
  <div class="wrapper">
    <strong>
      Earn up to 10% of your referrals'
      deposits!
    </strong>
    <p>
      Check Full <a class="link" :href="termsUrl">T&C</a>
    </p>
    <p>
      Send the below code to your friend, so they can input when they Register for a {{defaultCurrency.ticker}} {{ referAndEarn.award }}
      (equivalent in crypto) free bet.
    </p>
    <BaseTextField
      :model-value="referAndEarn.code"
      label="Your Referral Code"
      type="text"
      readonly="true"
      icon-name="copy"
    />
  </div>
</template>

<style scoped>
  .wrapper {
    border-radius: 8px;
    margin: 0 16px 16px;
  }

  .link {
    text-decoration: underline;
  }
</style>
