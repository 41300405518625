<script setup>
import { useNavigation } from "../../app/composables/useNavigations";
import { router } from "@/app/router";
import {
  capitalizeHyphenatedString,
  countryMapping,
  sortingOfArrayofObject,
} from "../utils/general";

const props = defineProps({
  sport: {
    type: Object,
    // required: true,
    default: null,
  },
  type: {
    type: String,
  },
  sportSlug: {
    type: String,
    default: null,
  },
  timeFrame: {
    type: String,
    default: null,
  },
  showMatchesStatus: {
    type: Boolean,
    default: true,
  },
  loadTournaments: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: Object,
    default: () => {},
  },
  params: {
    type: Object,
  },
});

const { navigations, findSportByName } = useNavigation();
function handleCountryClick(country) {
  router.push({
    name: "sport.league",
    params: {
      countrySlug: country,
    },
  });
}
</script>

<template>
  <div class="comp-heading">Full Country List A-Z</div>
  <div class="comp-country">
    <div
      v-for="country in sortingOfArrayofObject(
        Object.values(findSportByName(capitalizeHyphenatedString(props.sportSlug))?.categories)
      )"
      class="country"
      @click="() => handleCountryClick(country.id)"
    >
      <div class="country-logo">
        <img
          class="league-flag"
          :src="`/assets/images/flags/${countryMapping[country.name] || 'xx'}.svg`"
          alt=""
        />
        <div class="league-name">
          {{ country.name }}
        </div>
      </div>
      <img src="/assets/images/live/view-all.svg" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comp-heading {
  color: white;
  font-size: 19px;
  margin: 20px 0;
  padding: 0 10px;
}

@media screen and (max-width: 500px) {
  .comp-heading {
    font-size: 16px;
  }
}

.league-flag {
  width: 32px;
  height: 25px;
  border: 1px solid white;
}

.comp-league {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  margin-left: 10px;

  .league-header {
    display: flex;
    gap: 0 25px;
    width: 58%;
    align-items: center;
    .league-name {
      font-size: 15px;
      color: white;
    }
  }
}

.comp-country {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 0;
  color: white;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 10px;

  .country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    margin-left: 10px;
    cursor: pointer;

    .country-logo {
      display: flex;
      gap: 0 25px;
      align-items: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .comp-country {
    margin: 0px 11px 0 3px;
    .country {
      width: 45%;
    }
  }
}
</style>
