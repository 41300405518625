<script setup lang="ts">
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { router, useRoute } from "@/app/router";
import { useConfig } from "@/app/composables/useConfig";

const { appDivision } = useConfig();

const route = useRoute();
function goBack() {
  if (route.meta?.backRoute) {
    if (route.meta.backRoute === "/") {
      router.push({ name: appDivision.value });
      return;
    }
    router.push({ name: route.meta.backRoute });
  } else {
    router.go(-1);
  }
}
// const disableCloseButton = route.meta?.disableCloseButton ?? false;
const disableCloseButton = ["maintenance", "coming-soon"].includes(route.name as string);
</script>

<template>
  <div class="app-wrapper">
    <div class="left-panel notebook-only">
      <div class="logo">
        <img src="/Logo.svg" />
      </div>
    </div>
    <div class="content">
      <BaseButton v-if="!disableCloseButton" class="back-button tablet-only" @click="goBack">
        <BaseIcon name="arrow-left" size="24" />
      </BaseButton>
      <BaseButton v-if="!disableCloseButton" class="close-button notebook-only" @click="goBack">
        <BaseIcon name="close" size="24" />
      </BaseButton>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" class="one-component-wrapper" />
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-wrapper {
  background-color: var(--be-background-color);
  // position: fixed;
  width: 100%;
  display: flex;
  position: fixed;
  .mobile &,
  .tablet & {
    position: relative;
  }

  .left-panel {
    background-image: url(/assets/images/left-background.jpg);
    // background-image: url(./../../assets/images/left-background.jpg);
    width: 40%;
    height: 100vh;
    background-position: center;
    background-size: cover;

    .logo {
      text-align: center;

      img {
        max-width: 200px;
        margin: 20px auto;
      }
    }
  }

  .content {
    width: 60%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    overflow-y: auto;
    scrollbar-gutter: stable;
    height: 100vh;
    // padding: 0 10%;

    .mobile &,
    .tablet & {
      width: 100%;
      padding: 10px;
      height: unset;
    }

    .notebook & {
      width: 70%;
    }
    // .desktop & {
    //   padding: 0;
    // }

    .close-button {
      position: fixed;
      top: 2em;
      right: 2em;
    }

    .back-button {
      position: fixed;
      top: 1.2em;
      left: 0.7em;
      padding-left: 1em;
    }
  }
}

:deep(.section-header) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

/* .dialog-content {
  padding-bottom: 150px;
} */
// .right-sidebox {
//   overflow-y: auto;
//   height: 100vh;
// }
</style>
