<script setup></script>

<template>
  <div class="rules">
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit ratione ducimus
      commodi! Maiores, enim sit? Molestias totam nulla sed mollitia iure, vel, obcaecati,
      reprehenderit ipsam nemo quod velit? Voluptates, numquam.
    </p>
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam quo hic accusantium excepturi
      eos animi sed repellat porro! Quasi nostrum inventore rem porro accusamus ratione qui officia
      voluptatem quis reiciendis?
    </p>
  </div>
</template>

<style lang="scss" scoped>
.rules {
  font-family: Poppins-reg;
  font-size: 14px;
}
</style>
