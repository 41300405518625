<script>
import SimplePage from "./SimplePage.vue";

export default {
  name: "MaintenanceView",
  components: { SimplePage },
};
</script>

<template>
  <SimplePage
    title="We will be back soon!"
    body="We are currently undergoing routine maintenance to better improve your experience."
    suffix="Regards, BetKudos team."
  />
</template>
