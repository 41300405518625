<script setup>
import { computed } from "vue";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";
import { toast } from "@/app/utils/notification";
import { useBetStore } from "/store";

const props = defineProps({
  marketType: {
    type: Object,
    required: true,
  },
  competition: {
    type: Object,
    required: true,
  },
  odds: {
    type: Array,
    required: true,
  },
});

const store = useBetStore();

const isMobile = computed(() => {
  return store.getScreenWidth < 512;
});

const betSlip = useBetslip();

const fullTimeResultOdds = computed(() => {
  return props.odds.filter((odd) => odd.marketType === props.marketType.betGeniusId);
});

function getOutcomeName(odd) {
  if (odd.outcomeName === "Home") {
    return props.competition.participants[0].name;
  }

  if (odd.outcomeName === "Away") {
    return props.competition.participants[1].name;
  }

  if (odd.outcomeName === "Over") {
    return `${odd.outcomeName} ${odd.specifiers}`;
  }

  if (odd.outcomeName === "Under") {
    return `${odd.outcomeName} ${odd.specifiers}`;
  }

  return odd.outcomeName;
}

function formatPrice(price) {
  return price.toFixed(2);
}

function onOddClick(odd) {
  if (betSlip.selections.value.length >= 20) {
    toast.error("Max 20 selections per bet");
    return;
  }

  const selection = {
    eventName: `${props.competition.participants[0].name} v ${props.competition.participants[1].name}`,
    marketName: props.marketType.name,
    id: odd.id,
    marketId: odd.marketType,
    eventId: odd.competitionId,
    sportId: odd.sportId,
    specifiers: odd.specifiers,
    odds: odd.price,
    name: odd.outcomeName,
    header: odd.specifiers ? `${odd.outcomeName} ${odd.specifiers}` : odd.outcomeName,
    handicap: null,
    isSuspended: false,
    is_live: odd.isLive,
    order: null,
    specifier: "",
    awayTeamName: props.competition.participants[1].name,
    homeTeamName: props.competition.participants[0].name,
  };

  betSlip.toggleSelection2(selection);
}

function isSelected(odd) {
  return betSlip.selections.value.some((selection) => selection.id === odd.id);
}
</script>

<template>
  <div class="result">
    <div>{{ props.marketType.name }}</div>
    <div class="items" :class="{ mobile: isMobile }">
      <div
        v-for="odd in fullTimeResultOdds"
        :key="odd.id"
        class="item-box"
        :class="{ selected: isSelected(odd) }"
        @click="onOddClick(odd)"
      >
        <div class="item-label">
          {{ getOutcomeName(odd) }}
        </div>

        <div class="item-quantity">
          {{ formatPrice(odd.price) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.result {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  font-size: 12px;
  padding: 10px;
  margin-bottom: 1rem;

  .items {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    margin-top: 10px;

    &.mobile {
      grid-template-columns: auto;
    }

    .item-box {
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      background: var(--be-small-card-bg);
      width: 100%;
      padding: 1rem;
      cursor: pointer;

      &.selected {
        background: #f09426;

        .item-quantity {
          color: var(--be-big-card-bg);
        }
      }

      .item-label {
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 0.3rem;
      }

      .item-quantity {
        color: #f09426;
        text-align: left;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 1036px) {
    .items {
      grid-template-columns: auto;
    }
  }
}
</style>
