<script setup>
import { computed } from "vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

// import { useStore } from "vuex";
import { useBetStore } from "../../../store";

const emit = defineEmits(["handle-show-modal"]);

const store = useBetStore();

const showBet = computed(() => store.bet);
const showBetModal = computed(() => store.betModal);

function handleShowModal() {
  if (store.betModal && store.section == "bet") {
    store.handleShowBetModal();
  } else if (store.betModal && store.section != "bet") {
    store.setSection("bet");
  } else {
    store.handleShowBetModal();
    store.setSection("bet");
  }
}
</script>

<template>
  <div
    :class="`user-bat-section ${showBetModal && showBet ? 'active' : 'non-active'}`"
    @click="handleShowModal"
  >
    <BaseIcon
      :name="showBetModal && showBet ? 'bet-close' : 'bet-button-icon'"
      color="var(--be-primary-btn-label)"
      size="18"
    />
    <!-- <img
      :src="`/assets/images/${showBetModal && showBet ? 'bet-close' : 'bet-button-icon'}.svg`"
      alt=""
    /> -->
    <div>My bets</div>
  </div>
</template>

<style lang="scss" scoped>
.user-bat-section {
  border-radius: 4px;
  display: flex;
  margin-left: 11px;
  justify-content: center;
  align-items: center;
  gap: 0 8px;
  width: 102px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.active {
  background: #fff;
  color: black;
}

.non-active {
  background: var(--be-primary-btn);
  color: var(--be-primary-btn-label);
}
</style>
