<script>
import SimplePage from "./SimplePage.vue";

export default {
  name: "GeoBlockedView",
  components: { SimplePage },
};
</script>

<template>
  <SimplePage
    title="Your Region is Geo-Blocked :("
    body="You have accessed Betevet from a region that is prohibited under our Licencing agreement."
    suffix="Are you using a VPN or proxy? If so, try turning it off and refresh the page to be able to login."
    :show-back-button="true"
  />
</template>

<style scoped></style>
