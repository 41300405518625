<script setup>
import { onMounted, ref } from "vue";
import SelectItem from "../../SelectItem.vue";
import DepositFiat from "./DepositFiat.vue";
import DepositCrypto from "./DepositCrypto.vue";
import WithdrawFlutterwave from "./WithdrawFlutterwave.vue";
import WithdrawCrypto from "./WithdrawCrypto.vue";
import { t } from "@/app/composables/useI18n";
import { api } from "@/services";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

const allowedCurrencies = ref([]);
const providers = ref([]);
const loading = ref(false);
const selectedCurrency = ref({});
const selectedProvider = ref({});

async function loadAllowedCurrencies() {
  allowedCurrencies.value = [];

  loading.value = true;
  const result = await api.finance.allowedCurrencies("deposit");
  allowedCurrencies.value = result.currencies;
  loading.value = false;
  selectedCurrency.value = allowedCurrencies.value[0];

  if (allowedCurrencies.value.length > 0) {
    loadProviders(allowedCurrencies.value[0].id);
  }
}

async function loadProviders(currencyId) {
  providers.value = [];

  loading.value = true;
  providers.value = await api.finance.providers(currencyId, "deposit");
  loading.value = false;
  selectedProvider.value = providers.value[0];
}

function selectCurrency(currency) {
  selectedCurrency.value = currency;

  loadProviders(currency.id);
}

onMounted(() => {
  loadAllowedCurrencies();
});
</script>

<template>
  <div v-if="allowedCurrencies.length > 0">
    <div class="label">
      {{ t("finance.select_currency") }}
    </div>
    <div class="select-item">
      <SelectItem :value="selectedCurrency.name">
        <template #header>
          <div
            v-for="currency in allowedCurrencies"
            @click="selectCurrency(currency)"
            :key="currency.id"
          >
            {{ currency.name }}
          </div>
        </template>
      </SelectItem>
    </div>

    <div v-if="providers.length > 1" class="label">
      {{ t("finance.select_billing") }}
    </div>
    <div v-if="providers.length > 1" class="select-item">
      <SelectItem :value="selectedProvider.name">
        <template #header>
          <div
            v-for="provider in providers"
            @click="selectedProvider = provider"
            :key="provider.id"
          >
            {{ provider.name }}
          </div>
        </template>
      </SelectItem>
    </div>

    <DepositFiat
      v-if="
        props.type === 'deposit' && ['flutterwave', 'sentinal'].includes(selectedProvider?.alias)
      "
      :currency-id="parseInt(selectedCurrency.id)"
      :provider-id="parseInt(selectedProvider.id)"
    />
    <DepositCrypto
      v-else-if="props.type === 'deposit' && ['coins-paid'].includes(selectedProvider?.alias)"
      :currency-id="parseInt(selectedCurrency.id)"
      :provider-id="parseInt(selectedProvider.id)"
    />
    <WithdrawFlutterwave
      v-else-if="props.type === 'withdraw' && ['flutterwave'].includes(selectedProvider?.alias)"
      :currency-id="parseInt(selectedCurrency.id)"
      :provider-id="parseInt(selectedProvider.id)"
    />
    <WithdrawCrypto
      v-else-if="props.type === 'withdraw' && ['coins-paid'].includes(selectedProvider?.alias)"
      :currency-id="parseInt(selectedCurrency.id)"
      :provider-id="parseInt(selectedProvider.id)"
    />
    <div v-else>
      <span v-if="props.type === 'deposit'">{{ t("finance.deposit_unsupported") }}</span>
      <span v-else>{{ t("finance.withdraw_unsupported") }}</span>
    </div>
  </div>
  <div v-else>
    <span v-if="props.type === 'deposit'">{{ t("finance.deposit-not-allowed") }}</span>
    <span v-else>{{ t("finance.withdrawals_not_posible_message") }}</span>
  </div>
</template>

<style lang="scss" scoped>
.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 12px 0px;
}

.withdraw-fee {
  font-size: 12px;
}

.label {
  margin-bottom: 7px;
}

.select-item {
  margin-bottom: 10px;
  height: 40px;
}

.deposit-amount {
  font-size: 14px;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  border: none;
  height: 40px;
  outline: none;
  width: 100%;
  color: white;
  text-indent: 10px;
}

.min-deposit {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.qr-code {
  display: flex;
  padding: 16px;
  border-radius: 4px;
  background: var(--be-small-card-bg);
  gap: 0 16px;
}
</style>
