<script setup>
import { computed, ref } from "vue";
import Sports from "./Sports/index.vue";
import Casino from "./Casino/index.vue";
import Virtuals from "./Virtuals/index.vue";
import { useConfig } from "@/app/composables/useConfig";
import { useBetStore } from "/store.js";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);

const store = useBetStore();

const { appDivision } = useConfig();

const tabs = computed(() => {
  const tabs = [
    { title: "Sports", icon: "sports-cas", value: "sportsbook" },
    { title: "Casino", icon: "casino", value: "casino" },
    { title: "Virtuals", icon: "virtuals", value: "virtuals" },
  ];

  return tabs.filter((tab) => store.getModules.includes(tab.value));
});

const components = {
  sportsbook: Sports,
  casino: Casino,
  virtuals: Virtuals,
};

const selectedTab = ref(store.getModules[0]);

const isVisibleTabs = computed(() => {
  return tabs.value.length > 1;
});

const iconBase = "/assets/images/bets/mybets/";
</script>

<template>
  <div>
    <div v-if="isVisibleTabs" class="bets">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ 'active-bet': selectedTab === tab.value }"
        class="bet"
        @click="selectedTab = tab.value"
      >
        <!-- <img
          :src="`${iconBase + tab.icon}${selectedTab === tab.value ? '-active' : ''}.svg`"
          alt=""
        /> -->
        <BaseIcon
          :name="tab.icon"
          size="18"
          :color="
            selectedTab === tab.value
              ? 'var(--be-primary-btn-text-label)'
              : 'var( --be-primary-text-color-opacity)'
          "
        />
        <div>
          {{ tab.title }}
        </div>
      </div>
    </div>

    <component :is="components[selectedTab]" />
  </div>
</template>

<style lang="scss" scoped>
.bets {
  display: flex;
  gap: 0 16px;
  justify-content: center;
  border-radius: 10px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 10px 16px;
}

.bet {
  color: rgba(255, 255, 255, 0.3);
  width: 191px;
  height: 40px;
  display: flex;
  gap: 0 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.active-bet {
  color: var(--be-primary-btn-label);
  background: var(--be-primary-btn);
}
</style>
