<!-- eslint-disable no-unused-vars -->
<script setup>
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useMarketGroups } from "../composables/useMarketGroups.js";
import { useSportsdata } from "../composables/useSportsdata.js";
import SportSubheader from "./SportSubheader.vue";
import LeagueCoupons from "@/sportsbook/components/LeagueCoupons.vue";
import { useScreenSize } from "@/app/composables/useScreenSize.ts";
import { rebuildLayout } from "@/sportsbook/utils/layout";
import { updateNestedProperties } from "@/app/utils/events";
import { useEventSource } from "@/sportsbook/composables/useEventSource";

const props = defineProps({
  sport: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
  },
  sportSlug: {
    type: String,
    default: null,
  },
  timeFrame: {
    type: String,
    default: null,
  },
  showMatchesStatus: {
    type: Boolean,
    default: true,
  },
  loadTournaments: {
    type: Boolean,
    default: false,
  },
  filters: {
    type: Object,
    default: () => {},
  },
  params: {
    type: Object,
  },
});

const emit = defineEmits(["loading"]);

const el = ref(null);
const tournaments = ref([]);
const preTournaments = ref({});
const activeSport = ref();

const { screenFactor } = useScreenSize();
const { getSportBySlug, fetchSportEvents } = useSportsdata();
const { data, error } = useEventSource();
const { marketGroups, setLayoutMode, setSport } = useMarketGroups();

watch(data, (newValue) => {
  if (newValue) {
    const newUpdatedData = newValue.map((item) => {
      const keys = [
        item.tournamentId,
        "matches",
        item.competitionId,
        "markets",
        item.marketType,
        "odds",
        item.id,
      ];
      const updates = {
        odds: item.price,
        isActive: item.is_active,
      };

      updateNestedProperties(tournaments.value, keys, updates);

      // Loop on betslips

      return item;
    });
  }
});

watch(
  [() => props.sportSlug, () => props.sport, () => props.timeFrame],
  async () => {
    if (props.sport) {
      activeSport.value = props.sport;
    } else if (props.sportSlug) {
      activeSport.value = getSportBySlug(props.sportSlug);
    } else {
      return;
    }
    setSport(activeSport.value);
    if (activeSport.value.tournaments) {
      tournaments.value = Object.values(activeSport.value.tournaments);
      preTournaments.value = activeSport.value.tournaments;
    } else {
      emit("loading", true);
      tournaments.value = [];
      tournaments.value = await fetchSportEvents(activeSport.value.id, props.filters);
      preTournaments.value = tournaments.value;

      tournaments.value = Object.values(tournaments.value);
      emit("loading", false);
      nextTick(() => {
        rebuildLayout();
      });
    }
  },
  { immediate: true }
);

onMounted(() => {
  watch(
    screenFactor,
    (screenFactor) => {
      if (["mobile", "tablet"].includes(screenFactor) && el.value) {
        const elWidth = +getComputedStyle(el.value).width.replace("px", "");
        if (elWidth > 600) {
          setLayoutMode(2);
        } else {
          setLayoutMode(1);
        }
      }
    },
    { immediate: true }
  );
});
</script>

<template>
  <div v-if="activeSport && Object.values(tournaments).length > 0" ref="el" class="sport-coupons">
    <SportSubheader
      :title="activeSport.slug"
      :market-groups="marketGroups.value"
      :show-status="showMatchesStatus"
      :type="props.type"
    />

    <div v-for="league in Object.values(tournaments)" :key="league.id">
      <LeagueCoupons
        :type="props.type"
        :sport="activeSport"
        :market-groups="marketGroups.value"
        :league="league"
      />
    </div>
  </div>
</template>
