<script setup>
import { ref } from "vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { router } from "@/app/router";
import { api } from "@/services";
import { useAuth } from "@/user-account/composables/useAuth";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useBetStore } from "../../../store";
const props = defineProps({
  isFullscreen: {
    type: Boolean,
    default: false,
  },
  game: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update:isFullscreen"]);

const { user } = useUserService();
const store = useBetStore();
const { isAuthenticated } = useAuth();

const isFavourite = ref(false);

async function toggleFavourite() {
  isFavourite.value = !isFavourite.value;
  if (!isAuthenticated()) {
    store.handleShowLogin();
  } else {
    const result = await api.casino.updateFavourite({
      gameId: props.game.id,
      isFavourite: isFavourite.value,
    });
    isFavourite.value = result.isFavourite;
  }
}
</script>

<template>
  <nav class="lobby-actions">
    <BaseButton
      title="Favourite"
      class="actions-button text"
      :class="{ active: isFavourite }"
      @click="toggleFavourite()"
    >
      <BaseIcon name="heart" />
    </BaseButton>
    <div class="actions-center">
      <BaseButton
        class="actions-button text notebook-only"
        @click="emit('update:isFullscreen', !props.isFullscreen)"
      >
        <BaseIcon :name="props.isFullscreen ? 'fullscreen' : 'fullscreen-exit'" />
      </BaseButton>

      <BaseButton class="actions-button text" @click="router.go(-1)">
        <!-- <BaseButton class="actions-button text" @click="router.push({ name: 'casino' })"> -->
        <BaseIcon name="close" />
      </BaseButton>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.lobby-actions {
  display: flex;
  color: #ffffff8f;
  background: grey;
  // padding: 10px 1em;
  height: 36px;
  align-items: end;
  position: absolute;
  bottom: -36px;
  left: 0;
  width: 100%;
  z-index: 2;
  & > div {
    flex-direction: row;
  }
  .actions-center {
    display: flex;
    align-items: center;

    .tablet & {
      flex-direction: row-reverse;
    }
  }

  .actions-button {
    margin-top: -10px;

    // background: var(--be-card-background-color) !important;
    // border: var(--be-card-background-color) 1px solid;
    .base-icon {
      margin: auto;
    }

    .tablet & {
      margin-right: 7px;
    }
  }
}
</style>
