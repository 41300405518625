<script setup>
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService.js";
import VirtualGamesGameList from "@/virtual-games/components/VirtualGamesGameList.vue";
import Banner from "@/app/components/Banner.vue";

const { loading } = useVirtualGamesService();
</script>

<template>
  <article class="virtual-games-view">
    <!-- <PromoBanners /> -->
    <!-- <Banner
      img="/assets/images/banners/virtual"
      heading1="Virtual Gaming"
      heading2="Football Coming Soon!"
      button-label="Prepare for the Ultimate Experience!"
      mblbtn-label="- Prepare for the Ultimate Experience!"
    /> -->
    <div class="banner">
      <picture>
        <source media="(max-width:600px)" srcset="/assets/images/banners/virtual-mbl.jpg" />
        <img class="banner-img" :src="`/assets/images/banners/virtual.jpg`" alt="" />
      </picture>
    </div>
    <ProgressLoader :loading="loading" />
    <VirtualGamesGameList />
  </article>
</template>

<style scoped>
.virtual-games-view {
  width: 100%;
  min-height: 70vh;
}
</style>
